.colorbar{
  background: rgba(73, 73, 73, 0.3);
  width: max-content;
  display: block;
  padding: 0.5rem 1rem;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

.colorbar i {
  background: transparent;
  padding: 0.7rem;
  border-radius: 50%;
  display: flex;
  font-size: 1.3rem;
}

.colorbar #red{
  color: rgb(250, 134, 134);
}
.colorbar #blue{
  color: rgb(134, 200, 250);
}
.colorbar #green{
  color: rgb(134, 250, 182);
}


.colorbar i:hover{
  background: rgba(0,0,0,0.4);
}

/* will be for classname with active representing current page user is on */
.colorbar i.active {
  background: var(--color-light);
  color: black;
}

