.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio_item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  min-width: 15rem;
}

.portfolio_item:hover {
  border-color: var(--color-primary-variant);
  background: rgb(44, 44, 108, 0.5);
}

.portfolio_item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_item h3 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.portfolio_item h5 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.portfolio_item-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Media Queries (Tablet) */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
