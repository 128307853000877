.about__container{
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me{
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary),transparent);
  /* display: grid;
  place-items: center; */
  
}

.about__me-image{
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover{
  transform: rotate(0deg);
}

.about__cards{
  display: grid;
}

.about__content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about__content p {
  margin: 2rem 0 2.6rem;
  font-size: 1.5rem;
  color: var(--color-light);
  
}

/* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px){
  .about__container{
    grid-template-columns: 1fr;
    gap: 0;
    justify-items: center;
  }

  .about__me{
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p{
    margin: 1rem 0 1.5rem;
  }

  
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px){

  .about__me{
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__content{
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
 
}