.container.contact__container{
  background: var(--color-bg-variant);
  width: 60%;
  max-width: 720px;
  padding: 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.avatar {
  background-color: aliceblue;
  background-image: url(../../assets/avatar.png);
  background-repeat: no-repeat;
  width: 4rem;
  background-size: contain;
  aspect-ratio: 1/1;
  border-radius: 50%;
  /* margin: 0 auto 1rem; */
  border: 5px solid rgb(17, 39, 54);
}

.contact-text{
  text-align: center;
  color: var(--color-light);
  font-weight: 600;
  font-size: 1rem;
  width: 90%;
  margin: 0.8rem auto 0;
}

.contact-icon {
  font-size: 2rem;
}

@media screen and (max-width: 1024px) {
  .container.contact__container{
    width: 70%;
  }


}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
  .container.contact__container{
    width: var(--container-width-sm);
  }
}